import React from "react";
import appOverview from "../img/mob-shadow.png";
import { strings } from "../Translations/strings";
import "../style.scss";
import { Container, Image } from "react-bootstrap";

const AppOverview = () => {
  return (
    <Container id="overview" className="overview-container">
      <div className="app-overview d-flex">
        <Image
          src={appOverview}
          alt="app overview img"
          className="app-overview-img d-none d-md-flex"
        ></Image>
        <div className="d-flex flex-column overview-info-container">
          <h3 className="overview-title text-secondary">
            {strings.appoverview_title_label}
          </h3>
          <p className="overview-text text-regular">
            {strings.appoverview_text_label_first_part}
            <br></br>
            <br></br>
            {strings.appoverview_text_label_second_part}
            <br></br>
            <br></br>
            {strings.appoverview_text_label_third_part}
          </p>
        </div>
        <Image
          src={appOverview}
          alt="app overview img"
          className="app-overview-img d-flex d-md-none"
        ></Image>
      </div>
    </Container>
  );
};

export default AppOverview;
