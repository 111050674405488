import React, { useState } from "react";
import logoWhite from "../img/logo-white.svg";
import { BsInstagram } from "react-icons/bs";
import { BsTwitter } from "react-icons/bs";
import { strings } from "../Translations/strings";
import "../style.scss";
import {
  Container,
  Row,
  Col,
  Image,
  Navbar,
  Modal,
  Button,
} from "react-bootstrap";
import { Link } from "react-scroll";
import TAC from "./TAC";

function TermsAndConditions(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title
          id="contained-modal-title-vcenter"
          className="pt-5 px-2 px-md-3 px-lg-5"
        >
          <h4 className="tac-title">{strings.terms_title}</h4>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-4 p-md-3 p-lg-5 m-md-3">
        <TAC />
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
}

const Footer = () => {
  const [modalShow, setModalShow] = useState(false);
  const toggleModal = () => setModalShow(!modalShow);

  return (
    <Container fluid className="bg-basic footer-section mt-5">
      <Row className="pt-5">
        <Col xs={4}></Col>
        <Col xs={4} className="text-center">
          <Image src={logoWhite}></Image>
        </Col>
        <Col xs={4}></Col>
      </Row>
      <Row className="pt-5">
        <Col lg={3}></Col>
        <Col xs={12} lg={6}>
          <Navbar>
            <Container className="d-flex flex-wrap">
              <Navbar.Brand href="" className="text-primary footer-styling">
                <Link to="overview" smooth={true} duration={500} offset={-30}>
                  {strings.footer_item_one}
                </Link>
              </Navbar.Brand>
              <Navbar.Brand href="" className="text-primary footer-styling">
                <Link to="features" smooth={true} duration={500}>
                  {strings.footer_item_two}
                </Link>
              </Navbar.Brand>
              <Navbar.Brand
                className="text-primary footer-styling"
                onClick={toggleModal}
              >
                {strings.footer_item_three}
              </Navbar.Brand>
            </Container>
          </Navbar>
        </Col>
        <Col lg={3}></Col>
      </Row>
      <Row className="text-primary px-lg-5 mx-lg-5 mt-4 pb-5 footer-credits">
        <div className="footer-credits-container">
          <Col
            xs={{ span: 12, order: 2 }}
            lg={{ span: 9, order: 1 }}
            xl={{ span: 10, order: 1 }}
            className="text-center text-lg-start"
          >
            <p>
              © {new Date().getFullYear()} {strings.footer_credits}
            </p>
          </Col>
          <Col
            xs={{ span: 12, order: 1 }}
            lg={{ span: 3, order: 2 }}
            xl={{ span: 2, order: 1 }}
            className="d-flex justify-content-center mt-5 mt-md-0 mb-3 mb-lg-0"
          >
            <a
              className="footer-icon"
              target="_blank"
              href="https://www.instagram.com/Thesessionsapp/"
              rel="noreferrer"
            >
              <BsInstagram />
            </a>{" "}
            <a
              className="footer-icon"
              target="_blank"
              href="https://twitter.com/Thesessionsapp/"
              rel="noreferrer"
            >
              <BsTwitter />{" "}
            </a>
          </Col>
        </div>
      </Row>
      <TermsAndConditions show={modalShow} onHide={toggleModal} />
    </Container>
  );
};

export default Footer;
