import React from "react";
import quotes from "../img/quotes.svg";
import testimonialsAlicia from "../img/testimonials-alicia.svg";
import testimonialsStanley from "../img/testimonials-stanley.svg";
import { strings } from "../Translations/strings";
import "../style.scss";
import { Container, Row, Col, Image, Carousel, Card } from "react-bootstrap";

const Testimonials = () => {
  return (
    <Container
      fluid
      className="testimonials-section text-center p-md-2 pt-5"
      id="testimonials"
    >
      <Image src={quotes} className="testimonials-img" alt="quotes"></Image>
      <Row className="g-lg-2 mt-lg-5">
        <hr className="bg-primary mx-auto opacity-100 horizontal-line" />
        <h3 className="clients-title text-primary text-center m-0">
          <span className="d-block d-md-none">
            {strings.testimonials_label_mob}
          </span>
          <span className="d-none d-md-block">
            {strings.testimonials_label_pc}
          </span>
        </h3>
      </Row>
      <Row className="px-md-2 px-lg-5 pb-5 testimonials-carousel-row">
        <Carousel
          fade
          touch={false}
          variant="dark"
          data-bs-interval="false"
          className="testimonials-carousel"
        >
          <Carousel.Item interval={5000000}>
            <Col xs={12} sm={6} className="text-center">
              <Card className="p-lg-4 mx-1 mx-md-3 mx-lg-3 mt-5 p-3 testimonials-card">
                <Card.Body className="p-2 m-2">
                  <Card.Title className="text-center mb-3 testimonials-title text-dark">
                    {strings.testimonials_card_one_title}
                  </Card.Title>
                  <Card.Text className="testimonials-text">
                    {strings.testimonials_card_one_text}
                  </Card.Text>
                </Card.Body>
              </Card>
              <Col className="d-flex flex-row flex-wrap justify-content-center">
                <Image
                  src={testimonialsAlicia}
                  roundedCircle
                  className="testimonials-client-img me-5 mt-4"
                ></Image>
                <h5 className="text-primary mt-4 testimonials-client">
                  {strings.testimonials_client_one}
                </h5>
              </Col>
            </Col>
            <Col xs={12} sm={6} className="d-none d-sm-block">
              <Card className="p-lg-4 mx-1 mx-md-3 mx-lg-3 mt-5 p-3 testimonials-card">
                <Card.Body className="p-2 m-2">
                  <Card.Title className="text-center mb-3 testimonials-title text-dark">
                    {strings.testimonials_card_two_title}
                  </Card.Title>
                  <Card.Text className="testimonials-text">
                    {strings.testimonials_card_two_text}
                  </Card.Text>
                </Card.Body>
              </Card>
              <Col className="d-flex flex-row flex-wrap justify-content-center">
                <Image
                  src={testimonialsStanley}
                  roundedCircle
                  className="testimonials-client-img me-5 mt-4"
                ></Image>
                <h5 className="text-primary mt-4 testimonials-client">
                  {strings.testimonials_client_two}
                </h5>
              </Col>
            </Col>
          </Carousel.Item>
          <Carousel.Item>
            <Col xs={12} md={6} className="d-block d-sm-none text-center">
              <Card className="p-lg-4 mx-lg-3 mt-5 p-3 testimonials-card">
                <Card.Body className="p-2 m-2">
                  <Card.Title className="text-center mb-3 testimonials-title text-dark">
                    {strings.testimonials_card_two_title}
                  </Card.Title>
                  <Card.Text className="testimonials-text">
                    {strings.testimonials_card_two_text}
                  </Card.Text>
                </Card.Body>
              </Card>
              <Col className="d-flex flex-row flex-wrap justify-content-center">
                <Image
                  src={testimonialsStanley}
                  roundedCircle
                  className="testimonials-client-img me-5 mt-4"
                ></Image>
                <h5 className="text-primary mt-4 testimonials-client">
                  {strings.testimonials_client_two}
                </h5>
              </Col>
            </Col>
          </Carousel.Item>
        </Carousel>
      </Row>
    </Container>
  );
};

export default Testimonials;
