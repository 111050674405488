import React from "react";
import connectImg3 from "../img/connect-img-3.svg";
import { strings } from "../Translations/strings";
import "../style.scss";
import {Container, Row, Col, Image} from "react-bootstrap";

const ConnectSectionTwo = () => {
return(
    <Container className="p-5 bg-primary">
        <Row className="p-lg-5 g-5">
            <Col xs={12} lg={6}>
                <h5 className="text-darker connect-two-title mb-5"><span className="d-none d-sm-block">{strings.connect_two_find_producers_title_pc}</span><span  className="d-block d-sm-none">{strings.connect_two_find_producers_title_mob}</span></h5>
                <p className="text-darker connect-two-text">{strings.connect_two_find_producers_text_one}<br></br><br></br>{strings.connect_two_find_producers_text_two}</p>
            </Col>
            <Col xs={12} lg={6}>
                <Image src={connectImg3} className="connect-two-img" alt="connect img"></Image>
            </Col>
        </Row>
    </Container>
)
};

export default ConnectSectionTwo;