import React from "react";
import advantagesMob from "../img/advantages-img.png";
import icon1 from "../img/icon-1.svg";
import icon2 from "../img/icon-2.svg";
import icon3 from "../img/icon-3.svg";
import { strings } from "../Translations/strings";
import "../style.scss";
import { Container, Row, Col, Card, Image } from "react-bootstrap";

const AppAdvantages = () => {
  return (
    <Container id="features">
      <Row className="g-2 mt-5 px-2">
        <hr className="bg-green mx-auto opacity-100 horizontal-line" />
        <h3 className="clients-title text-secondary text-center m-0">
          {strings.appadvantages_title_label}
        </h3>
      </Row>
      <Row className="mt-5">
        <Col md={4} lg={5} className="d-none d-md-block advantages-col">
          <Image src={advantagesMob} alt="bg image" className="hero-im"></Image>
        </Col>
        <Col md={8} lg={7} className="mt-5 align-self-center">
          <Card border="primary" className="mb-5">
            <Card.Body className="app-card">
              <Card.Img variant="left" className="app-icon me-5" src={icon1} />
              <div>
                <Card.Title className="text-dark advantages-title mt-none mt-md-2">
                  {strings.appadvantages_list_one}
                </Card.Title>
                <Card.Text className="text-light advantages-text">
                  {" "}
                  {strings.appadvantages_list_p_one}
                </Card.Text>
              </div>
            </Card.Body>
          </Card>
          <Card border="primary" className="mb-5">
            <Card.Body className="app-card">
              <Card.Img variant="left" className="app-icon me-5" src={icon2} />
              <div>
                <Card.Title className="text-dark advantages-title mt-none mt-md-2">
                  {strings.appadvantages_list_two}
                </Card.Title>
                <Card.Text className="text-light advantages-text">
                  {" "}
                  {strings.appadvantages_list_p_two}
                </Card.Text>
              </div>
            </Card.Body>
          </Card>
          <Card border="primary" className="mb-5">
            <Card.Body className="app-card">
              <Card.Img variant="left" className="app-icon me-5" src={icon3} />
              <div>
                <Card.Title className="text-dark advantages-title mt-none mt-md-2">
                  {strings.appadvantages_list_three}
                </Card.Title>
                <Card.Text className="text-light advantages-text">
                  {" "}
                  {strings.appadvantages_list_p_three}
                </Card.Text>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default AppAdvantages;
