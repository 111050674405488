import React from "react";
import doodle1 from "../img/doodle-1.png";
import doodle2 from "../img/doodle-2.svg";
import doodle3 from "../img/doodle-3.svg";
import doodleMob from "../img/doodle-mob.svg";
import { strings } from "../Translations/strings";
import "../style.scss";
import { Container, Row, Col, Image, Form, Button } from "react-bootstrap";
import emailjs from "@emailjs/browser";
import { useState, useRef } from "react";

const Newsletter = () => {
  const [validated, setValidated] = useState(false);
  const [white, setWhite] = useState(true);

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      setWhite(false);
    }
    if (form.checkValidity() === true) {
      sendEmail(event);
      handleReset(event);
      setValidated(true);
      setWhite(true);
    }
  };

  const formReference = useRef(null);

  const handleReset = () => {
    formReference.current.reset();
  };

  const sendEmail = (e) => {
    e.preventDefault();
    emailjs.sendForm(
      "service_fhpqo2k",
      "template_rjpp2ve",
      e.target,
      "2I2hjdeJodtzn4bCt"
    );
    e.target.reset();
  };

  const styled = {
    backgroundColor: white ? "#fff" : "#ffefed",
  };
  const displayed = {
    display: white ? "none" : "block",
  };
  const placeholder1 = {
    content: validated
      ? strings.newsletter_placeholder1_one
      : strings.newsletter_placeholder1_two,
  };

  return (
    <Container className="newsletter-section-container">
      <Row className="p-5 p-lg-5 m-lg-5">
        <Col className="pt-md-5 mt-lg-5 newsletter-bg rounded newsletter-col">
          <Image src={doodle1} className="newsletter-doodle-1"></Image>
          <Image src={doodle2} className="newsletter-doodle-2"></Image>
          <Image
            src={doodle3}
            className="newsletter-doodle-3 d-none d-md-block"
          ></Image>
          <Image
            src={doodleMob}
            className="newsletter-doodle-mob d-block d-md-none"
          ></Image>
          <Row className="pt-5 mt-5 px-md-5">
            <Col xs={12} lg={6} className="p-4 p-lg-2">
              <h3 className="newsletter-title text-night">
                {strings.newsletter_title}
              </h3>
              <p className="newsletter-text text-secondary">
                {strings.newsletter_text}
              </p>
            </Col>
            <Col xs={12} lg={6} className="p-4 p-lg-2">
              <Form
                noValidate
                validated={validated}
                ref={formReference}
                style={styled}
                className="d-lg-flex rounded newsletter-form-row"
                onSubmit={handleSubmit}
              >
                <Form.Group className="newsletter-group">
                  <Form.Control
                    required
                    style={styled}
                    type="email"
                    name="email"
                    placeholder={placeholder1.content}
                    className="border-0 newsletter-placeholder text-dim mb-3 mb-lg-0  newsletter-form"
                  />
                  <Form.Control.Feedback
                    style={displayed}
                    type="invalid"
                    className="newsletter-feedback"
                  >
                    {strings.newsletter_invalid}
                  </Form.Control.Feedback>
                </Form.Group>
                <Button
                  variant="gradient"
                  type="submit"
                  value="Send"
                  className="gradient-primary text-primary btn-gradient newsletter-button"
                >
                  {strings.newsletter_button}
                </Button>
              </Form>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default Newsletter;
