import React from "react";
import connectImg from "../img/connect-img.png";
import connectMob from "../img/connect-mob.png";
import { strings } from "../Translations/strings";
import "../style.scss";
import { Container, Row, Col, Image } from "react-bootstrap";

const ConnectSectionOne = () => {
  return (
    <Container
      fluid
      className="connect-section-one bg-green d-flex justify-content-center"
    >
      <div className="connect-container">
        <Row>
          <Col
            xs={{ span: 12, order: 2 }}
            md={{ span: 12, order: 2 }}
            lg={{ span: 7, order: 1 }}
          >
            <Image
              src={connectImg}
              className="connect-one-image d-none d-md-flex"
              alt="connect img"
            ></Image>
            <Image
              src={connectMob}
              className="connect-one-image d-flex d-md-none"
              alt="connect img"
            ></Image>
          </Col>
          <Col
            xs={{ span: 12, order: 1 }}
            md={{ span: 12, order: 1 }}
            lg={{ span: 4, order: 3 }}
          >
            <h5 className="text-primary connect-one-title">
              {strings.connect_one_find_producers_title}
            </h5>
            <p className="text-primary connect-one-text">
              {strings.connect_one_find_producers_text_one}
              <br></br>
              <br></br>
              {strings.connect_one_find_producers_text_two}
            </p>
          </Col>
        </Row>
      </div>
    </Container>
  );
};

export default ConnectSectionOne;
