import React from "react";
import './style.scss';
import HeroSection from "./HeroSection";
import AppOverview from './AppOverview';
import SessionsClients from "./SessionsClients";
import AppAdvantages from "./AppAdvantages";
import SliderSection from "./SliderSection";
import ConnectSectionOne from "./ConnectSectionOne";
import ConnectSectionTwo from "./ConnectSectionTwo";
import Testimonials from "./Testimonials";
import FAQ from "./FAQSection";
import Newsletter from "./Newsletter";
import Footer from "./Footer";

function App() {
  return (
    <div className="App">
      <HeroSection />
      <AppOverview />
      <SessionsClients />
      <AppAdvantages />
      <SliderSection />
      <ConnectSectionOne />
      <ConnectSectionTwo />
      <Testimonials />
      <FAQ />
      <Newsletter />
      <Footer />
    </div>
  );
}

export default App;
