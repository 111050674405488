import React from "react";
import faqImage from "../img/faq-image.jpg";
import faqImageMob from "../img/faq-image-mob.jpg";
import { strings } from "../Translations/strings";
import "../style.scss";
import { Container, Row, Col, Image } from "react-bootstrap";

const FAQ = () => {
  return (
    <Container className="p-md-5 pt-5" id="contact">
      <Row className="g-md-2 mb-0 mb-5 p-md-5 faq-row">
        <hr className="bg-green mx-auto opacity-100 horizontal-line" />
        <h3 className="clients-title text-secondary text-center m-0">
          {strings.faq_label}
        </h3>
      </Row>
      <Row className="g-5 p-2 p-md-4 m-0">
        <Col md={6}>
          <h5 className="faq-question text-dark">{strings.faq_question_one}</h5>
          <p className="faq-answer text-dark">{strings.faq_answer_one}</p>
        </Col>
        <Col md={6}>
          <h5 className="faq-question text-dark">{strings.faq_question_two}</h5>
          <p className="faq-answer text-dark">{strings.faq_answer_two}</p>
        </Col>
      </Row>
      <Row className="g-5 p-2 p-md-4 m-0 mb-5">
        <Col md={6}>
          <h5 className="faq-question text-dark">
            {strings.faq_question_three}
          </h5>
          <p className="faq-answer text-dark">{strings.faq_answer_three}</p>
        </Col>
        <Col md={6}>
          <h5 className="faq-question text-dark">
            {strings.faq_question_four}
          </h5>
          <p className="faq-answer text-dark">{strings.faq_answer_four}</p>
        </Col>
      </Row>
      <Row className="pt-md-5 p-4">
        <Col>
          <Image
            src={faqImage}
            rounded
            className="w-100 d-none d-md-block"
          ></Image>
          <Image
            src={faqImageMob}
            rounded
            className="w-100 d-block d-md-none"
          ></Image>
        </Col>
      </Row>
      <Row className="mt-4 gx-5 p-4 m-0">
        <Col xs={12} lg={6}>
          <h3 className="faq-need-help text-dark mb-5">
            <span className="d-none d-md-block">
              {strings.faq_need_help_label_pc}
            </span>
            <span className="d-block d-md-none">
              {strings.faq_need_help_label_mob}
            </span>
          </h3>
        </Col>
        <Col xs={12} lg={6}>
          <p className="faq-need-help-p text-dark">{strings.faq_need_help}</p>
        </Col>
      </Row>
    </Container>
  );
};

export default FAQ;
