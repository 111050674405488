import React from "react";
import { strings } from "../Translations/strings";

const TAC = () => {
    return(
        <><p className="tac-text">{strings.terms_one}</p>
          <p className="tac-text">{strings.terms_two}</p>
          <p className="tac-text">{strings.terms_three}</p>
          <h4 className="my-5 tac-subtitle">{strings.terms_definitions_title}</h4>
          <p className="tac-text">{strings.terms_definitions_one}</p>
          <p className="tac-text">{strings.terms_definitions_two}</p>
          <p className="tac-text">{strings.terms_definitions_three}</p>
          <p className="tac-text">{strings.terms_definitions_four}</p>
          <p className="tac-text">{strings.terms_definitions_five}</p>
          <p className="tac-text">{strings.terms_definitions_six}</p>
          <p className="tac-text">{strings.terms_definitions_seven}</p>
          <p className="tac-text">{strings.terms_definitions_eight}</p>
          <h4 className="my-5 tac-subtitle">{strings.terms_legal_compliance}</h4>
          <p className="tac-text">{strings.terms_legal_compliance_one}</p>
          <h4 className="my-5 tac-subtitle">{strings.terms_change_to_terms}</h4>
          <p className="tac-text" >{strings.terms_change_to_terms_one}</p>
          <p className="tac-text">{strings.terms_change_to_terms_two}</p>
          <p className="tac-text">{strings.terms_change_to_terms_three}</p>
          <p className="tac-text">{strings.terms_change_to_terms_four}</p>
          <h4 className="my-5 tac-subtitle">{strings.terms_account_registration_security}</h4>
          <p className="tac-text">{strings.terms_account_one}</p>
          <p className="tac-text">{strings.terms_account_two}</p>
          <p className="tac-text">{strings.terms_account_three}</p>
          <h4 className="my-5 tac-subtitle">{strings.terms_eligibility}</h4>
          <p className="tac-text">{strings.terms_eligibility_one}</p>
          <p className="tac-text">{strings.terms_eligibility_two}</p>
          <p className="tac-text">{strings.terms_eligibility_three}</p>
          <p className="tac-text">{strings.terms_eligibility_four}</p>
          <h4 className="my-5 tac-subtitle">{strings.terms_using_our_services}</h4>
          <p className="tac-text">{strings.terms_using_our_services_one}</p>
          <p className="tac-text">{strings.terms_using_our_services_two}</p>
          <p className="tac-text">{strings.terms_using_our_services_three}</p>
          <p className="tac-text">{strings.terms_using_our_services_four}</p>
          <h4 className="my-5 tac-subtitle">{strings.terms_premium_services}</h4>
          <p className="tac-text">{strings.terms_premium_services_one}</p>
          <p className="tac-text">{strings.terms_premium_services_two}</p>
          <h4 className="my-5 tac-subtitle">{strings.terms_ownership}</h4>
          <p className="tac-text">{strings.terms_ownership_one}</p>
          <p className="tac-text">{strings.terms_ownership_two}</p>
          <p className="tac-text">{strings.terms_ownership_three}</p>
          <p className="tac-text">{strings.terms_ownership_four}</p>
          <p className="tac-text">{strings.terms_ownership_five}</p>
          <p className="tac-text">{strings.terms_ownership_six}</p>
          <p className="tac-text">{strings.terms_ownership_seven}</p>
          <p className="tac-text">{strings.terms_ownership_eight}</p>
          <p className="tac-text">{strings.terms_ownership_nine}</p>
          <p className="tac-text">{strings.terms_ownership_ten}</p>
          <p className="tac-text">{strings.terms_ownership_eleven}</p>
          <p className="tac-text">{strings.terms_ownership_twelve}</p>
          <p className="tac-text">{strings.terms_ownership_thirteen}</p>
          <p className="tac-text">{strings.terms_ownership_fourteen}</p>
          <p className="tac-text">{strings.terms_ownership_fifteen}</p>
          <p className="tac-text">{strings.terms_ownership_sixteen}</p>
          <p className="tac-text">{strings.terms_ownership_seventeen}</p>
          <h4 className="my-5 tac-subtitle">{strings.terms_monitoring}</h4>
          <p className="tac-text">{strings.terms_monitoring_one}</p>
          <p className="tac-text">{strings.terms_monitoring_two}</p>
          <p className="tac-text">{strings.terms_monitoring_three}</p>
          <h4 className="my-5 tac-subtitle">{strings.terms_prohibited_uses}</h4>
          <p className="tac-text">{strings.terms_prohibited_one}</p>
          <h4 className="my-5 tac-subtitle">{strings.terms_interactions}</h4>
          <p className="tac-text">{strings.terms_interactions_one}</p>
          <h4 className="my-5 tac-subtitle">{strings.terms_third_party_advertising}</h4>
          <p className="tac-text">{strings.terms_third_party_one}</p>
          <p className="tac-text">{strings.terms_third_party_two}</p>
          <p className="tac-text">{strings.terms_third_party_three}</p>
          <p className="tac-text">{strings.terms_third_party_four}</p>
          <h4 className="my-5 tac-subtitle">{strings.terms_copyright}</h4>
          <p className="tac-text">{strings.terms_copyright_one}</p>
          <h4 className="my-5 tac-subtitle">{strings.terms_feedback}</h4>
          <p className="tac-text">{strings.terms_feedback_one}</p>
          <p className="tac-text">{strings.terms_feedback_two}</p>
          <h4 className="my-5 tac-subtitle">{strings.terms_availability}</h4>
          <p className="tac-text">{strings.terms_availability_one}</p>
          <p className="tac-text">{strings.terms_availability_two}</p>
          <p className="tac-text">{strings.terms_availability_three}</p>
          <h4 className="my-5 tac-subtitle">{strings.terms_limitations}</h4>
          <p className="tac-text">{strings.terms_limitations_one}</p>
          <h4 className="my-5 tac-subtitle">{strings.terms_governing_law}</h4>
          <p className="tac-text">{strings.terms_governing_law_one}</p>
          <h4 className="my-5 tac-subtitle">{strings.terms_separation_of_terms}</h4>
          <p className="tac-text">{strings.terms_separation_of_terms_one}</p>
          <h4 className="my-5 tac-subtitle">{strings.terms_assignment}</h4>
          <p className="tac-text">{strings.terms_assignment_one}</p>
          <h4 className="my-5 tac-subtitle">{strings.terms_entire_agreement}</h4>
          <p className="tac-text">{strings.terms_entire_agreement_one}</p>
          <h4 className="my-5 tac-subtitle">{strings.terms_language}</h4>
          <p className="tac-text">{strings.terms_language_one}</p>
          <h4 className="my-5 tac-subtitle">{strings.terms_no_waiver}</h4>
          <p className="tac-text">{strings.terms_no_waiver_one}</p>
          <h4 className="my-5 tac-subtitle">{strings.terms_notices}</h4>
          <p className="tac-text">{strings.terms_notices_one}</p>
          <p className="tac-text">{strings.terms_notices_two}</p>
          <h4 className="my-5 tac-subtitle">{strings.terms_contact_info}</h4>
          <p className="tac-text">{strings.terms_contact_info_one}</p>
          </>
    )
}

export default TAC;