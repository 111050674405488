import React from "react";
import logo from "../img/logo.svg";
import ellipse from "../img/ellipse-1.svg";
import heroUpdated from "../img/hero-img-updated.png";
import { strings } from "../Translations/strings";
import "../style.scss";
import { Container, Navbar, Image } from "react-bootstrap";
import { Link } from "react-scroll";

const HeroSection = () => {
  return (
    <Container fluid>
      <Container className="hero-section d-flex">
        <div className="d-flex flex-column info-container">
          <Navbar className="ms-md-nav">
            <Container className="nav-container">
              <Navbar.Brand href="/" className="nav-styling">
                <img alt="logo img" src={logo} width="112" height="40" />
              </Navbar.Brand>
              <Navbar.Brand
                href=""
                className="text-lighter nav-styling d-none d-lg-block"
              >
                <Link to="overview" smooth={true} duration={500} offset={-60}>
                  {strings.navbar_overview_label}
                </Link>
              </Navbar.Brand>
              <Navbar.Brand
                href=""
                className="text-lighter nav-styling d-none d-lg-block"
              >
                <Link to="features" smooth={true} duration={500}>
                  {strings.navbar_features_label}
                </Link>
              </Navbar.Brand>
              <Navbar.Brand
                href=""
                className="text-lighter nav-styling d-none d-lg-block"
              >
                <Link to="testimonials" smooth={true} duration={500}>
                  {strings.navbar_testimonials_label}
                </Link>
              </Navbar.Brand>
              <Navbar.Brand
                href=""
                className="text-lighter nav-styling d-none d-lg-block"
              >
                <Link to="contact" smooth={true} duration={500} offset={800}>
                  {strings.navbar_contact_label}
                </Link>
              </Navbar.Brand>
            </Container>
          </Navbar>
          <Image
            src={heroUpdated}
            alt="bg image"
            className="hero-image d-flex d-md-none"
          ></Image>
          <div className="d-flex flex-column hero-intro">
            <h3 className="text-secondary intro-title">
              {strings.card_title_first_label}
            </h3>
            <h3 className="text-secondary intro-title">
              {strings.card_title_second_label}
            </h3>
            <h3 className="text-secondary intro-title">
              {strings.card_title_third_label}
            </h3>
            <p className="d-none d-md-flex hero-p">{strings.card_text_label}</p>
            {/*<Button variant="gradient" className="gradient-primary text-primary btn-gradient mt-3">{strings.card_button}</Button>/*/}
          </div>
        </div>
        <div>
          <Image src={ellipse} alt="bg image" className="hero-bg-image"></Image>
          <Image
            src={heroUpdated}
            alt="bg image"
            className="hero-image d-none d-md-flex"
          ></Image>
        </div>
      </Container>
    </Container>
  );
};

export default HeroSection;
