import React from "react";
import sliderImg1 from "../img/slider-img-1.png";
import sliderImg2 from "../img/slider-img-2.png";
import sliderImg3 from "../img/slider-img-3.png";
import sliderImg4 from "../img/slider-img-4.png";
import { strings } from "../Translations/strings";
import "../style.scss";
import { Container, Row, Col, Carousel, Image } from "react-bootstrap";

const SliderSection = () => {
  return (
    <Container
      fluid
      className="slider-section d-flex flex-column justify-content-center"
      id="slider"
    >
      <Row className="mt-5 pt-lg-5 px-2">
        <hr className="bg-green mx-auto opacity-100 horizontal-line" />
        <h3 className="clients-title text-secondary text-center m-0">
          {strings.slider_section_title}
        </h3>
      </Row>
      <div className="d-flex justify-content-center">
        <div className="slider-container">
          <Carousel
            variant="dark"
            interval={null}
            className="px-md-5 mt-5 d-flex"
            data-bs-touch="false"
            data-bs-interval="false"
          >
            <Carousel.Item>
              <Row>
                <Col
                  xs={{ span: 12, order: 2 }}
                  md={{ span: 6, order: 1 }}
                  lg={{ span: 5, order: 1 }}
                  className="slider-info px-md-5 d-md-flex flex-column justify-content-center"
                >
                  <Carousel.Caption className="text-start px-5 px-md-0 px-lg-5">
                    <h3 className="slide-title text-darker">
                      {strings.slider_slide_one_title}
                    </h3>
                    <p className="slide-text text-black">
                      {strings.slider_slide_one_text}
                    </p>
                  </Carousel.Caption>
                </Col>
                <Col
                  xs={{ span: 12, order: 1 }}
                  md={{ span: 6, order: 2 }}
                  lg={{ span: 7, order: 2 }}
                  className="slider-info px-5"
                >
                  <Image
                    src={sliderImg1}
                    alt="slider img"
                    className="d-block slider-img"
                  ></Image>
                </Col>
              </Row>
            </Carousel.Item>
            <Carousel.Item>
              <Row>
                <Col
                  xs={{ span: 12, order: 2 }}
                  md={{ span: 6, order: 1 }}
                  lg={{ span: 5, order: 1 }}
                  className="slider-info px-md-5 d-md-flex flex-column justify-content-center"
                >
                  <Carousel.Caption className="text-start px-5 px-md-0 px-lg-5">
                    <h3 className="slide-title text-darker">
                      {strings.slider_slide_two_title}
                    </h3>
                    <p className="slide-text text-black">
                      {strings.slider_slide_two_text}
                    </p>
                  </Carousel.Caption>
                </Col>
                <Col
                  xs={{ span: 12, order: 1 }}
                  md={{ span: 6, order: 2 }}
                  lg={{ span: 7, order: 2 }}
                  className="slider-info px-5"
                >
                  <Image
                    src={sliderImg2}
                    alt="slider img"
                    className="d-block slider-img"
                  ></Image>
                </Col>
              </Row>
            </Carousel.Item>
            <Carousel.Item>
              <Row>
                <Col
                  xs={{ span: 12, order: 2 }}
                  md={{ span: 6, order: 1 }}
                  lg={{ span: 5, order: 1 }}
                  className="slider-info px-md-5 d-md-flex flex-column justify-content-center"
                >
                  <Carousel.Caption className="text-start px-5 px-md-0 px-lg-5">
                    <h3 className="slide-title text-darker">
                      {strings.slider_slide_three_title}
                    </h3>
                    <p className="slide-text text-black">
                      {strings.slider_slide_three_text}
                    </p>
                  </Carousel.Caption>
                </Col>
                <Col
                  xs={{ span: 12, order: 1 }}
                  md={{ span: 6, order: 2 }}
                  lg={{ span: 7, order: 2 }}
                >
                  <Image
                    src={sliderImg3}
                    alt="slider img"
                    className="d-block slider-img"
                  ></Image>
                </Col>
              </Row>
            </Carousel.Item>
            <Carousel.Item>
              <Row>
                <Col
                  xs={{ span: 12, order: 2 }}
                  md={{ span: 6, order: 1 }}
                  lg={{ span: 5, order: 1 }}
                  className="slider-info px-md-5 d-md-flex flex-column justify-content-center"
                >
                  <Carousel.Caption className="text-start  px-5 px-md-0 px-lg-5">
                    <h3 className="slide-title text-darker">
                      {strings.slider_slide_four_title}
                    </h3>
                    <p className="slide-text text-black">
                      {strings.slider_slide_four_text}
                    </p>
                  </Carousel.Caption>
                </Col>
                <Col
                  xs={{ span: 12, order: 1 }}
                  md={{ span: 6, order: 2 }}
                  lg={{ span: 7, order: 2 }}
                >
                  <Image
                    src={sliderImg4}
                    alt="slider img"
                    className="d-block slider-img"
                  ></Image>
                </Col>
              </Row>
            </Carousel.Item>
          </Carousel>
        </div>
      </div>
    </Container>
  );
};

export default SliderSection;
