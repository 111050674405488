export const strings = {
  navbar_overview_label: "Overview",
  navbar_features_label: "Features",
  navbar_testimonials_label: "Testimonials",
  navbar_contact_label: "Contact",
  card_title_first_label: "Unlimited Opportunities",
  card_title_second_label: "for Creating Limitless",
  card_title_third_label: "Music",
  card_title_fourth_label:
    "Unlimited Opportunities for Creating Limitless Music",
  card_text_label:
    "Find producers and songwriters to collaborate with anywhere in the world. Set up sessions and create the kind of music you have always dreamed of.",
  card_button: "Get Invited!",
  appoverview_title_label: "What is Session House? ",
  appoverview_text_label_first_part:
    "Get your foot in the music industry door and expand your online network of like-minded creatives.",
  appoverview_text_label_second_part:
    "As a songwriter, you can now sit at the same table as that lucky kid from NYC who may be discovered at their local club.",
  appoverview_text_label_third_part:
    "As a producer, you can now sign on and nurture the next big talent.",
  sessionsclients_title_part_one: "Who is Session House For?",
  sessionsclients_card_title_one: "Songwriters",
  sessionsclients_card_title_two: "Producers",
  sessionsclients_discover_part_one: "Discover ",
  sessionsclients_discover_part_two: "creatives ",
  sessionsclients_discover_part_three: "near you.",
  sessionsclients_card_text_one:
    "Get noticed and signed by a producer or find people to write incredible music with.",
  sessionsclients_card_text_two:
    "Find the creatives who are currently writing the lyrics everyone will be singing next year.",
  sessionsclients_p:
    "Meet your talented songwriter neighbor with Session House! Browse your local map and get to know the people who make music right in your hometown.  ",
  appadvantages_title_label: "What You’re Getting",
  appadvantages_list_one: "Get Exclusive Access",
  appadvantages_list_two: "Integrate with Muso.ai",
  appadvantages_list_three: "Grow Your Creative Network",
  appadvantages_list_p_one:
    "Meet the people you would never get the chance to collab with otherwise when you are invited to Session House.",
  appadvantages_list_p_two:
    "Easily list credits on your profile with our simple Muso.ai integration.",
  appadvantages_list_p_three:
    "Connect and jam with anybody in the world and enjoy the process of making music.",
  slider_section_title: "What Makes Session House Special?",
  slider_slide_one_title: "Create and Join Unlimited Sessions",
  slider_slide_two_title: "Easy Muso.ai Integration",
  slider_slide_three_title: "Searchable Interactive Map",
  slider_slide_four_title: "Premium Access with an Exclusive Invite",
  slider_slide_one_text:
    "Invite and get invited to limitless sessions and jam with anyone at any time.",
  slider_slide_two_text:
    "List all of your previous work in just a couple of clicks.",
  slider_slide_three_text:
    "Walk down any street and find local Sessions users you can collaborate with.",
  slider_slide_four_text:
    "Take the next step in your music career when you get invited to join The Sessions App.",
  connect_one_find_producers_title:
    "Find Producers or Songwriters Anywhere in the World",
  connect_one_find_producers_text_one:
    "You no longer need to move to a big city to make it in music, nor do you need to sign an unfavorable contract.",
  connect_one_find_producers_text_two:
    "Take your talent to the next level with the help of Session Home’s global community.",
  connect_two_find_producers_title_pc:
    "Connect with Local Producers and Songwriters and Create Awesome Music Together",
  connect_two_find_producers_title_mob:
    "Connect with producers or musicians and jam together.",
  connect_two_find_producers_text_one:
    "Get together with your talented local community and meet the people around you who have the same feel for music.",
  connect_two_find_producers_text_two:
    "Start a session with the person down the street you never knew had the same dreams.",
  testimonials_label_pc: "What Our Users Are Saying",
  testimonials_label_mob: "Our clients speak",
  testimonials_card_one_title: "Incredible Experience",
  testimonials_card_two_title: "Can’t Wait to See Where It Goes",
  testimonials_card_one_text:
    "Just finished my first session and loved every bit of it! Who knew there were so many talented people right in my own city. Can’t wait to see more people join, and meet other songwriters from around the globe.",
  testimonials_card_two_text:
    "Had a great time chatting to a songwriter from Philadelphia yesterday. The search feature is really useful, and helps you find just the kind of talent you are looking for. WIll certainly use it more and more often.",
  testimonials_client_one: "Alicia McAddams",
  testimonials_client_two: "Stanley Buttler",
  faq_label: "FAQ",
  faq_question_one: "Is Session House available for both iOS and Android?",
  faq_question_two: "Do I need to have a Muso.ai account?",
  faq_question_three: "Is Session House free?",
  faq_question_four: "How do I get access?",
  faq_answer_one:
    "Session House is launching on iOS in April 2022. Our Android app will be available in August 2022.",
  faq_answer_two:
    "You don’t need to have a Muso.ai account to use Session House. However, having one will help us validate your account and you will be able to list all your credits in just a couple of clicks.",
  faq_answer_three:
    "Yes! You can use Session House for free when you get an exclusive invite. Paid features and functionalities will however be added in the future.",
  faq_answer_four:
    "You need to be invited to Session House by an existing member. If none of your friends are using it yet, head over to our social media and get to know the community: one of them will hook you up.",
  faq_need_help_label_pc: "Need some assistence? We’re happy to help!",
  faq_need_help_label_mob: "If you need assistance, please contact us.",
  faq_need_help:
    "If you have any questions about our app, how it works, how to use it or about registering an account, please contact us at sessions@info.com.",
  newsletter_title: "Sign up for our newsletter.",
  newsletter_text: "Get the latest news and insight from Session House team.",
  newsletter_button: "Sign me up!",
  newsletter_placeholder1_one: "Subscribed!",
  newsletter_placeholder1_two: "Your email address",
  newsletter_invalid: "Please enter valid email.",
  footer_item_one: "About",
  footer_item_two: "Features",
  footer_item_three: "Terms and Conditions",
  footer_item_four: "Privacy Policy",
  footer_credits: "Session House. All rights reserved.",
  terms_title: "Session House Terms Of Service",
  terms_one:
    "The Sessions App is owned and operated by The Sessions App AB, a company registered and existing under the laws of Sweden.",
  terms_two:
    "Please read these Terms and Service and our Privacy Policy carefully before accessing or making use of our App and Services. All users of our App, and other products and services are conditioned upon acceptance of these Terms of Service and our Privacy Policy. We may require you to accept further terms that apply to certain features or services.",
  terms_three:
    "By clicking to indicate your acceptance or otherwise further access or use of any part of our services, you agree to be bound by these Terms of Service and our Privacy Policy. If you do not agree to these Terms, you are not allowed to use our Services. Any new or updated features or tools that are added to the current App and our services shall also be subject to these Terms of Service.",
  terms_definitions_title: "Definitions",
  terms_definitions_one:
    "'Our App' or 'App' - refers to “The Sessions App” which includes: all pages, sub-pages, subdomains, and any associated web-based application (collectively, 'App') owned and operated by “The Sessions App AB”.",
  terms_definitions_two:
    "'We', 'Us', and 'Our'- shall be used interchangeably and shall refer to The Sessions App, its owners, its affiliates and/or subsidiaries, and any person or entities associated with The Sessions App AB.",
  terms_definitions_three:
    "'Visitor'- is someone who merely browses our app but has not registered an account.",
  terms_definitions_four:
    "'Member'- is someone that has registered an account with us to make use of our App and services.",
  terms_definitions_five:
    "'User' or 'Users' or 'You'- is a collective identifier that refers to either a Visitor or Member.",
  terms_definitions_six:
    "'Account'- means an account created by a Member to access our Services.",
  terms_definitions_seven:
    "'Services'- shall collectively mean our services, App, and all contents",
  terms_definitions_eight:
    "'User Content'- collectively refers to all the data that users upload, transmit, create, or generate on or while using our Services. This includes Member profile pictures, in-app text or chat, audio recordings, streamed contents, and or other content authored by a User.",
  terms_legal_compliance: "Legal Compliance",
  terms_legal_compliance_one:
    "By using our Services, you agree to comply with all applicable local and international laws, statutes, ordinances, and regulations. We reserve the right to investigate reported violations of any of our operational Terms and to take necessary action we deem appropriate, including but not limited to canceling Services we render you, reporting any suspected illicit activity to law enforcement bodies, regulators, or other third parties.",
  terms_change_to_terms: "Change To These Terms",
  terms_change_to_terms_one:
    "We reserve the right, at our sole discretion and without prior notice, to make additions, modifications, remove, or change portions/all these Terms at any time. Users will be notified about any changes through the App. In addition, Members will be notified through a message sent to their associated account e-mail.",
  terms_change_to_terms_two:
    "Changes will automatically be effective when posted unless we state otherwise. We urge Users to review the latest version of these Terms on this page any time they make use of our Services. Your continued use of our Services without reading these Terms and their subsequent updates any time you use our Services means your acceptance of the latest version of these Terms",
  terms_change_to_terms_three:
    "In cases of disputes, the version of this Terms of Service at the time we receive your dispute notice will be applicable in resolving your dispute with us.",
  terms_change_to_terms_four:
    "These Terms of Service and our Privacy Policy shall govern should any other policies of ours conflict with any provisions of these Terms and our Privacy Policy.",
  terms_account_registration_security: "Account Registration and Security",
  terms_account_one:
    "To use certain features of our Services, you will be required to register an account by providing us with certain identifiable personal information (such as your name, date of birth, e-mail address, in some cases, payment information, etc.) and choosing a secure password. All personal information provided is safe and secure. Kindly read our Privacy Policy to see how we use and secure your data.",
  terms_account_two:
    "All personal information you supply us must be accurate, complete, and promptly updated if there is any change, particularly your email address.",
  terms_account_three:
    "Your account is not transferable or assignable and is void where prohibited. You agree to maintain the confidentiality of your password and are fully responsible for all liability and damages resulting from your failure to maintain that confidentiality and all activities that occur through your account whether authorized by you or not, including any payment. You agree to immediately notify us of any unauthorized use of your password or any other breach of security. You agree that we cannot and will not be liable for any loss or damage arising from your failure to comply with password security as discussed herein.",
  terms_eligibility: "Eligibility",
  terms_eligibility_one:
    "Who is eligible to use our Services: We are happy to have you use our Services, but our Services are limited to eligible Users.",
  terms_eligibility_two:
    "• You are not permitted to use our Services if you are below the age of 13. Users below the age of majority require a parental or legal guardian to agree to be bound by these Terms and other terms applicable to the use of our Services. Such parental or legal guardian shall be fully responsible for any acts or omissions of the User below the age of majority.",
  terms_eligibility_three:
    "• You are not eligible to use our Services or a certain part of our Services if they are restricted in your jurisdiction. Using our Services in jurisdictions where they are restricted is at your sole risk, you are responsible for compliance with all local laws, rules, and regulations that may apply. Furthermore, we reserved the right to restrict your usage or impose additional eligibility requirements when necessary.",
  terms_eligibility_four:
    "• You are prohibited from further use of our Services if you have been suspended or banned from our Services. You can be eligible again if your suspension or ban is lifted at our sole discretion",
  terms_using_our_services: "Using Our Services",
  terms_using_our_services_one:
    "The Sessions App provides you Services through our platforms while you provide the equipment ( phones, computer, tablet, e.t.c) needed to access our Services. To use our Services, you may need an account with us or have an account with a social media network (like Facebook or Gmail) which can be used to log into our services after you grant us certain permissions.",
  terms_using_our_services_two:
    "If you access our Services through a social network or you downloaded our app through a third-party service like google play store or Apple then you must agree and comply with the terms of such a third-party platform.",
  terms_using_our_services_three:
    "From time to time, and to provide you with a better experience, our Services will likely evolve through remote updates which you will be required to accept. We reserve the right to discontinue, temporarily or permanently terminate or suspend our Services or part of our Services at any time.",
  terms_using_our_services_four:
    "Deleting your Account: You can delete your account at any time if you wish to stop using our Services. Upon deletion, you can further request that we delete your data in accordance with our Privacy Policy practice.",
  terms_premium_services: "Premium Services",
  terms_premium_services_one:
    "The Sessions App may sometimes offer paid products and services (“Premium Purchases”) through our direct billing, Apple Store, iTunes, Google Play Store, or any other payment processing company. By making Premium Purchases, you will have to confirm purchase by clicking the prompt message which permits the applicable payment provider and payment method to charge you the prices displayed to you for the service(s) you selected as well as applicable Tax. You hereby authorize The Sessions App AB and third-party payment processorsto charge your Credit Card or any other payment account you provide us.",
  terms_premium_services_two:
    "More information regarding payment processing for Premium Purchases may occasionally be included in additional agreements you may have with The Sessions App pertaining Premium Services.",
  terms_ownership: "Ownership; User Limited License",
  terms_ownership_one:
    "The Sessions App and our related Services: All contents provided to you through our Services are owned and licensed to The Sessions App. Our contents and all associated Services are trademarked, copyrighted, patented, and have obtained worldwide intellectual property rights. All rights are reserved. The Sessions App, subject to your acceptance and abiding by these Terms grants you a limited license to use our Services. You agree not to use our Services in any way that will tarnish our good public reputation or bring us any liability. We reserve the right to terminate, ban, and seek further legal actions against you if you breach any of these Terms.",
  terms_ownership_two:
    "YOUR ATTEMPT TO DISRUPT, MANIPULATE, HIJACK, HACK OR INTERFERE WITH THE SERVICES OR OUR OPERATIONS WILL BE CONSIDERED A BREACH OF OUR OPERATIONAL POLICY OR VIOLATION OF CRIMINAL AND CIVIL LAWS.",
  terms_ownership_three:
    "Content of Users: Our Services permit Users to create, share, and post content which includes but is not limited to messages, photos, videos, audios, graphical works, etc. (All collectively referred to as 'User Content') The User retains all rights to their content except for (a) any content from the Services that may be incorporated in your User Content, and (b) the license you grant below, as between you and The Sessions App.",
  terms_ownership_four:
    "The User hereby grants a non-exclusive, revocable, and limited license to The Sessions App to use the content freely, reproduce, prepare derivatives, distribute, use for marketing and promotions worldwide without any royalty owed to the User.",
  terms_ownership_five:
    "To post, transmit, upload, or publish any content on our Services, you agree that such content is:",
  terms_ownership_six:
    "• Originally yours, not in violation of any third-party rights, or that you have obtained adequate right and license from the owner of the content before posting.",
  terms_ownership_seven: "• Authentic and accurate.",
  terms_ownership_eight:
    "• not in violation of any local, federal, or international law.",
  terms_ownership_nine: "• not considered to be confidential.",
  terms_ownership_ten:
    "• not prohibited or in violation of any section of our Terms of service.",
  terms_ownership_eleven:
    "• free of malicious codes, worms, spyware, trojans, viruses, ransomware, adware, etc.",
  terms_ownership_twelve:
    "User license to The Sessions App ends upon data deletion request by the User. However, there are certain conditions and certain contents we may not be able to delete which are: ",
  terms_ownership_thirteen:
    "• User contents that are licensed to The Sessions App under different license terms and agreements.",
  terms_ownership_fourteen:
    "• Contents a User shared with other Users which has been made public or have been stored privately.",
  terms_ownership_fifteen:
    "We will take necessary steps to delete User Content from active use upon User request, this may include suppression of User content in our system. However, User Content may persist in our systems, including in backup copies. We may also retain copies of User Content if we reasonably believe it is legally required.",
  terms_ownership_sixteen:
    "Comments transmitted within the public section of our Service (blogs, forums, comment sections, chats, etc.) may be copied and stored outside our Service by other Users. We do not guarantee the safety of such User contents or ideas that were posted publicly, we urge Users not to post confidential or sensitive information in the public section of our Service. We won't be liable for any inappropriate use of your content that was copied from the public section of our Service by other Users ",
  terms_ownership_seventeen:
    "We reserve the right at our sole discretion to delete User content that doesn't comply with our Terms and other applicable terms. If such User content infringes the intellectual property rights of a third party, we may disclose your identity to such third party upon their request. Furthermore, your identity may be disclosed at the request of law enforcement agencies, government bodies, or by a court of law.",
  terms_monitoring: "Monitoring Use Of Services And User Content",
  terms_monitoring_one:
    "The Sessions App does not and is not obligated to monitor User content or conduct of Members using our Services. We do not endorse or approve content posted by Users so we cannot guarantee the accuracy or appropriateness of User content. Users are responsible for the content they post, The Sessions App is not responsible for such content and won't be liable for any damage caused by such information, materials, products, or services offered by other User through our Services. Using our Service is at your sole discretion, you understand you may be exposed to content that is offensive or Members with inappropriate behavior. If you encounter such offensive content or a Member violating our Terms or abusing our Services, Kindly let us know immediately by using the 'Report' button or contact customer support.",
  terms_monitoring_two:
    "While we are not obligated to monitor User contents, we may at our sole discretion, record, monitor, or store your communications with other Users. For more information, see our Privacy Policy",
  terms_monitoring_three:
    "Without notice, we may choose to modify, reject, or completely delete any User Content we find inappropriate or to be against our Terms and may further limit or terminate a Member's account for posting content that is against or violates our Services.",
  terms_prohibited_uses: "Prohibited Uses",
  terms_prohibited_one:
    "In addition to other prohibitions as set forth in the Terms of service, Users are prohibited from using the site or its content: (a) for any unlawful purpose; (b) to solicit others to perform or participate in any unlawful acts; (c) to violate any international, federal, provincial or state regulations, rules, laws, or local ordinances; (d) to infringe upon or violate our intellectual property rights or the intellectual property rights of others; (e) to harass, abuse, insult, harm, defame, slander, disparage, intimidate, or discriminate based on gender, sexual orientation, religion, ethnicity, race, age, national origin, or disability; (f) to submit false or misleading information; (g) to upload or transmit viruses or any other type of malicious code that will or may be used in any way that will affect the functionality or operation of the Service or of any related website, other websites, or the Internet; (h) to collect or track the personal information of others; (i) to spam, phish, pharm, pretext, spider, crawl, or scrape; (j) for any obscene or immoral purpose; or (k) to interfere with or circumvent the security features of the Service or any related website, other websites, or the Internet. We reserve the right to terminate your use of the Service or any related website for violating any of the prohibited uses.",
  terms_interactions: "Your Interactions With Other Members",
  terms_interactions_one:
    "Communications and interactions between Members are solely at the discretion of the Members involved, we do not interfere in such interactions unless during a dispute if we so desire. By communicating and interacting with other Members, you accept all liability you agree not to hold The Sessions App or anyone associated with The Sessions App AB responsible for any loss, claims, demands, damages, or compensations of any kind arising from your communication or interactions with another Member.",
  terms_third_party_advertising: "Third-Party Advertising",
  terms_third_party_one:
    "We may feature advertisements from third-party companies through links on our App, some of these third-party advertisements provide you promotional offers. Your decision to use those links is solely at your discretion, you agree to take full responsibility for your decision to use such third-party offers.",
  terms_third_party_two:
    "Our posting of third-party advertisements does not in any way constitute our affiliation, approval, or endorsement of such advertisements or their related offers. We do not have control of their business practices, the content posted on such sites, or the data they collect from you. Please endeavor to read their privacy policy page before making use of their services as they may or may not ask your permission before collecting that information.",
  terms_third_party_three:
    "You agree not you hold The Sessions App liable for any loss, harm, or damage arising from your decision to use such third-party advertisement links.",
  terms_third_party_four:
    "Our Privacy Policy explains what information we share with advertisers. Please read it.",
  terms_copyright: "Copyright Notices/Complaints",
  terms_copyright_one:
    "The Sessions App takes its intellectual property rights and that of others seriously and we require you to do so. We respond promptly to alleged copyright infringement notices in compliance with the US Digital Millennium Copyright Act (“DMCA”), the E-Commerce Directive and associated legislation in the EU, and similar or equivalent other local laws that may apply. The Sessions App reserve the right to terminate the account of any Member that infringes the intellectual property rights of others (repeatedly) without notice.",
  terms_feedback: "Feedback And Unsolicited Ideas",
  terms_feedback_one:
    "We may require you to leave us feedback on certain features through a customer insights program. Your decision to leave feedback is optional but we would like you to do so. If we require feedback from you through a program, your feedback is subject to the guidelines of the specific program.",
  terms_feedback_two:
    "All feedback, information, or idea you send to us voluntarily is subject to our unsolicited ideas.",
  terms_availability: "Availability Of The Services; Warranty Disclaimer",
  terms_availability_one:
    "Our Services may be temporarily unavailable from time to time for maintenance or other reasons. The Sessions App assumes no responsibility for any error, omission, interruption, deletion, defect, delay in operation or transmission, or communication failure. We reserve the right to suspend, withdraw, or restrict the availability of all or any part of our Services for business and operational reasons.",
  terms_availability_two:
    "OUR SERVICES AND ALL CONTENT, MATERIALS, AND PRODUCTS ARE PROVIDED ON AN 'AS IS' BASIS, WITH ALL 'DEFECTS” AND 'AS AVAILABLE' BASIS WITHOUT WARRANTIES OF ANY KIND, EXPRESS, IMPLIED, STATUTORY, OR OTHERWISE. THE SESSIONS APP DISCLAIMS ALL WARRANTIES, EXPRESS OR IMPLIED, INCLUDING WITHOUT LIMITATION, WARRANTIES OF TITLE, NON-INFRINGEMENT, AND IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, SATISFACTORY QUALITY, ACCURACY OF INFORMATIONAL CONTENT, AND ANY WARRANTIES ARISING OUT OF ANY USE OF OUR PRODUCTS AND SERVICES. THE SESSIONS APP MAKES NO REPRESENTATION THAT OUR MOBILE APP OR ANY CONTENT IS FREE FROM ERRORS, VIRUSES, BUGS, OR OTHER HARMFUL COMPONENTS. THE SESSIONS APP MAKES NO REPRESENTATION OR WARRANTY THAT CONTENT PROVIDED THROUGH OUR SERVICE IS APPLICABLE  OR APPROPRIATE FOR USE IN YOUR STATE OR COUNTRY.",
  terms_availability_three:
    "BY USING OUR SERVICES, YOU ACKNOWLEDGE THAT YOU DO SO AT YOUR OWN RISK",
  terms_limitations: "Limitations; Waiver Of Liability",
  terms_limitations_one:
    "IN NO EVENT WILL “THE SESSIONS APP AB” OR ITS DIRECTORS, EMPLOYEES, OR AGENTS BE LIABLE TO YOU OR ANY THIRD PERSON FOR ANY INDIRECT, CONSEQUENTIAL, EXEMPLARY, INCIDENTAL, OR PUNITIVE DAMAGES, INCLUDING FOR ANY LOST PROFITS OR LOST DATA ARISING FROM YOUR USE OF OUR SERVICES, CONTENT, OR ANY RELATED PRODUCT ACCESSED THROUGH OUR SERVICES, EVEN IF THE SESSIONS APP HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. NOTWITHSTANDING ANYTHING TO THE CONTRARY CONTAINED HEREIN, THE SESSIONS APP’S LIABILITY TO YOU FOR ANY CAUSE WHATSOEVER, AND REGARDLESS OF THE FORM OF THE ACTION WILL AT ALL TIMES BE LIMITED TO $200.",
  terms_governing_law: "Governing Law",
  terms_governing_law_one:
    "These Terms and Conditions and any separate agreements whereby we provide you Services shall be governed by and construed by the laws of Sweden.",
  terms_separation_of_terms: "Separation Of Terms",
  terms_separation_of_terms_one:
    "Each of the paragraphs of these Terms operates separately. If any part of these Terms is not enforceable, the rest of these Terms still apply and are binding, and any unenforceable term will be substituted with a term that comes as economically close to the unlawful or unenforceable term as possible.",
  terms_assignment: "Assignment",
  terms_assignment_one:
    "We reserve the right to assign our rights and obligations under these Terms to a different party or entity at any time without your consent. Users are not allowed to assign their rights or obligations under these Terms to any party without obtaining prior written consent from The Sessions App, any attempt to do so without our consent is void.",
  terms_entire_agreement: "Entire Agreement",
  terms_entire_agreement_one:
    "These Terms, and any other policies or rules we reference in these Terms, make up the entire agreement between you and The Sessions App relating to the subject matter of these Terms and supersede all prior understandings of the parties relating to the subject matter of these Terms, whether those prior understandings were electronic, oral or written, or whether established by custom, practice, policy or precedent, between you and us.",
  terms_language: "Language Of The Terms",
  terms_language_one:
    "Any translated version of these Terms of service is for informational use only. The translated version may not fully represent our position as it could mean something different from the English version. The English version remains the authentic version of these Terms",
  terms_no_waiver: "No Waiver",
  terms_no_waiver_one:
    "Our non-enforcement of our rights under these Terms at any time does not mean we waived the right to do so in the future. And, if we do expressly waive a provision of these Terms, that does not mean we have waived it in perpetuity. If we do waive a default or breach by you, we will only do so in writing, and that will not mean that we will automatically waive any later default or breach by you",
  terms_notices: "Notices",
  terms_notices_one:
    "Notices will be sent to your e-mail or posted within our mobile app or through other means of communication depending on the contact information we have on file.",
  terms_notices_two:
    "All notices from Users must be in writing and addressed to thesessionsapp.com unless we stipulate another specific means of sending us notices.",
  terms_contact_info: "Contact Information",
  terms_contact_info_one:
    "Questions about these Terms of Service should be sent to us at Info@thesessionsapp.com",
};
