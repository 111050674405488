import React from "react";
import { strings } from "../Translations/strings";
import "../style.scss";
import clientsCard1 from "../img/clients-card1.svg";
import clientsCard2 from "../img/clients-card2.svg";
import smallCircle from "../img/small-circle.svg";

const SessionsClients = () => {
  return (
    <div className="sessions-clients">
      <div className="c-container d-flex flex-column justify-content-center">
        <hr className="bg-green mx-auto opacity-100 horizontal-line" />
        <h3 className="clients-title text-secondary text-center">
          {strings.sessionsclients_title_part_one}
        </h3>
        <div className="cards-container d-flex justify-content-center">
          <div className="clients-card">
            <img
              src={clientsCard1}
              className="clients-card-image"
              alt="card"
            ></img>
            <h4 className="text-black clients-card-title">
              {strings.sessionsclients_card_title_one}
            </h4>
            <p className="clients-card-text text-light">
              {strings.sessionsclients_card_text_one}
            </p>
          </div>
          <div className="clients-card card-two">
            <img
              src={clientsCard2}
              className="clients-card-image"
              alt="card"
            ></img>
            <h4 className="text-black clients-card-title">
              {strings.sessionsclients_card_title_two}
            </h4>
            <p className="clients-card-text text-light">
              {strings.sessionsclients_card_text_two}
            </p>
          </div>
        </div>
        <div className="disc-container d-flex justify-content-center">
          <img
            src={smallCircle}
            className="small-circle d-none d-lg-block"
            alt="card"
          ></img>
          <div className="discover-container d-flex">
            <div className="discover-info">
              <h4 className="discover-title text-primary">
                {strings.sessionsclients_discover_part_one}
                {strings.sessionsclients_discover_part_two}
                {strings.sessionsclients_discover_part_three}
              </h4>
              <p className="text-primary discover-p">
                {strings.sessionsclients_p}
              </p>
            </div>
            <div className="text-center align-self-center">
              {/* <Button variant="primary" className="mb-5 mb-lg-0 text-green btn-gradient">{strings.card_button}</Button> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SessionsClients;
